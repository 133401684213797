<template>
  <div class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <h3>Notes and Images</h3>
      <textarea v-model="note" placeholder="Enter your notes"></textarea>
      <input type="file" @change="handleFileUpload" />
      <button @click="saveNote">Save</button>
      <button @click="closeModal">Close</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      note: '',
      image: null
    };
  },
  methods: {
    handleFileUpload(event) {
      this.image = event.target.files[0];
    },
    async saveNote() {
      // Prepare form data to send
      const formData = new FormData();
      formData.append('note', this.note);
      formData.append('image', this.image);
      formData.append('question_id', this.question_id); // Add question ID if needed

      try {
        // Post data to PHP API
        await axios.post('https://www.adesso.live/AVUE1/add_note.php', formData);
        this.$emit('close');
      } catch (error) {
        console.error('Error saving note:', error);
      }
    },
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>
