<template>
  <div class="question-item" :style="{ backgroundColor: currentColor }" @click="toggleColor">
    <span>{{ question }}</span>
    <button @click.stop="openModal" class="note-button">Add Note</button>

    <!-- Include the Note Modal -->
    <NoteModal v-if="showModal" @close="showModal = false" />
  </div>
</template>

<script>
import NoteModal from './NoteModal.vue';

export default {
  props: {
    question: String
  },
  components: {
    NoteModal
  },
  data() {
    return {
      currentColor: 'yellow',  // Initial color for the question
      showModal: false         // To control modal visibility
    };
  },
  methods: {
    // Method to toggle background color between yellow, green, and red
    toggleColor() {
      if (this.currentColor === 'yellow') {
        this.currentColor = 'green';
      } else if (this.currentColor === 'green') {
        this.currentColor = 'red';
      } else {
        this.currentColor = 'yellow';
      }
    },
    // Method to open the notes modal
    openModal() {
      this.showModal = true;
    }
  }
};
</script>

<style scoped>
.question-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  cursor: pointer;
}
.note-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
</style>
