<template>
  <div id="app">
    <h1>Audit Checklist</h1>
    <!-- Loop over questions and render a QuestionItem for each -->
    <div v-for="(question, index) in questions" :key="index">
      <QuestionItem :question="question" />
    </div>
  </div>
</template>

<script>
import QuestionItem from './components/QuestionItem.vue';

export default {
  components: {
    QuestionItem
  },
  data() {
    return {
      questions: [
        'Is the workspace clean?',
        'Are all safety protocols followed?',
        'Is equipment maintenance up to date?'
      ]
    };
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}
</style>
